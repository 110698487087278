html[light] {
    --dark-stage-1: #e5e7eb;
    --dark-stage-2: #fff;
    --text-color: #111827;
    --text-color-alt: #c4c4c4;

    --site-main-color: #1e1d21;
    --site-code-color: rgb(50, 51, 55);
    --site-code-background: rgb(242, 243, 245);

    --type-release: green;
    --type-prerelease: hsl(45deg 100% 59%);
    --type-draft: hsl(0deg 100% 73%);

    --release-hover-background: #ddd
}

html[light] code {
    border: 1px solid rgb(228, 229, 231);
}

html[light] hr {
    background: rgba(200, 200, 200, 0.6)  ;
}
